<template>
  <table class="table table-sm table-bordered table-sm">
    <thead>
      <tr>
        <th>Ticketnummer</th>
        <th>Kundennummer</th>
        <th>Name</th>
        <th width="1%">Postleitzahl</th>
        <th width="1%">Ort</th>
        <th class="text-center">Von</th>
        <th class="text-center">Bis</th>
        <th class="text-center" width="1%">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="booking in bookings" :key="booking._id">
        <td class="text-center">{{ booking.ticketNr }}</td>
        <td>{{ booking.customer.customerId }}</td>
        <td>{{ booking.customer.name }}</td>
        <td width="1%">{{ booking.customer.zipcode }}</td>
        <td width="1%">{{ booking.customer.city }}</td>
        <td class="text-center" width="1%">
          {{ booking.start | date('dd.MM.yyyy') }}
        </td>
        <td class="text-center" width="1%">
          {{ booking.end | date('dd.MM.yyyy') }}
        </td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-danger btn-block"
            :disabled="requesting || role === 'guest'"
            @click="onDelete(booking._id)"
          >
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'bookings-table',
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    requesting: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'guest',
    },
  },
  methods: {
    onDelete(id) {
      this.$emit('deleteBooking', id);
    },
  },
};
</script>
